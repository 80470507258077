import React from 'react';
import  logo  from '../../assets/images/mine/logo_light.png'

const TWITTER_URL = process.env.REACT_APP_TWITTER_ACCOUNT;
const FACEBOOK_URL = process.env.REACT_APP_FACEBOOK_ACCOUNT;
const INSTAGRAM_URL = process.env.REACT_APP_INSTAGRAM_ACCOUNT;
const LINKEDIN_URL = process.env.REACT_APP_LINKEDIN_ACCOUNT;

function Footer() {
  return (
    <footer class="bg-dark p-3">
	<div class="container">
		<div class="row align-items-center">
			{/* <!-- Widget --> */}
			<div class="col-md-4 text-center text-md-start mb-md-0">
				{/* <!-- Logo START --> */}
				<a href="index.html"> <img class="h-20px" src={logo} alt="logo"/> </a>
			</div>
			
			{/* <!-- Widget --> */}
			<div class="col-md-4 mb-3 mb-md-0 d-none d-md-block">
				<div class="text-center text-white">
					Copyrights ©2023 <a href="#" class="text-reset btn-link">Sareyat</a>. All rights reserved.
				</div>
			</div>
			{/* <!-- Widget --> */}
			<div class="col-md-4 d-none d-md-block">
				{/* <!-- Rating --> */}
				<ul class="list-inline mb-0 text-center text-md-end">
					<li class="list-inline-item ms-2"><a target='_blank' href={FACEBOOK_URL}><i class="text-white fab fa-facebook"></i></a></li>
					<li class="list-inline-item ms-2"><a target='_blank' href={INSTAGRAM_URL}><i class="text-white fab fa-instagram"></i></a></li>
					<li class="list-inline-item ms-2"><a target='_blank' href={LINKEDIN_URL}><i class="text-white fab fa-linkedin-in"></i></a></li>
					<li class="list-inline-item ms-2"><a target='_blank' href={TWITTER_URL}><i class="text-white fab fa-twitter"></i></a></li>
				</ul>
			</div>
		</div>
	</div>
</footer>
  )
}

export default Footer