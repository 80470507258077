import React from 'react'
import { useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom'
// import  logo  from '../../assets/images/mine/sareyat-logo.png'
import  logo  from '../../assets/images/mine/logo_light.png'
import { logout } from '../../redux/features/userSlice';

function Sidebar() {

  const dispatch = useDispatch();
  
  return (
    <nav class="navbar sidebar navbar-expand-xl navbar-dark bg-dark" style={{"overflow-y": "auto"}}>

	{/* <!-- Navbar brand for xl START --> */}
	<div class="d-flex align-items-center">
		<Link to='/' class="navbar-brand">
			<img class="navbar-brand-item" src={logo} alt=""/>
		</Link>
	</div>
	{/* <!-- Navbar brand for xl END --> */}
	
	<div class="offcanvas offcanvas-start flex-row"  data-bs-backdrop="true" tabindex="-1" id="offcanvasSidebar">
		<div class="offcanvas-body sidebar-content d-flex flex-column bg-dark">

			{/* <!-- Sidebar menu START --> */}
			<ul class="navbar-nav flex-column" id="navbar-sidebar">
				
				{/* <!-- Menu item 1 --> */}
				<li data-bs-dismiss="offcanvas" class="nav-item"><NavLink to='/dashboard' className="nav-link" end><i class="bi bi-house fa-fw me-2"></i>لوحة التحكم</NavLink></li>
				
				{/* <!-- Title --> */}
				<li class="nav-item ms-2 my-2">الأقسام</li>

				{/* <!-- menu item 2 --> */}
				<li class="nav-item">
					<a class="nav-link" data-bs-toggle="collapse" href="#collapsepage" role="button" aria-expanded="false" aria-controls="collapsepage">
						<i class="bi bi-basket fa-fw me-2"></i>الكورسات
					</a>
					{/* <!-- Submenu --> */}
					<ul class="nav collapse flex-column" id="collapsepage" data-bs-parent="#navbar-sidebar">
						<li data-bs-dismiss="offcanvas" class="nav-item"> <NavLink to='/dashboard/courses' className="nav-link">جميع الكورسات</NavLink></li>
						<li data-bs-dismiss="offcanvas" class="nav-item"> <NavLink to='/dashboard/publish-requests' className="nav-link">طلبات النشر</NavLink></li>
					</ul>
				</li>

				{/* <!-- Menu item 3 --> */}
				<li data-bs-dismiss="offcanvas" class="nav-item"> <NavLink to='/dashboard/students' className="nav-link"><i class="fas fa-user-graduate fa-fw me-2"></i>المسجلين</NavLink></li>

				{/* <!-- Menu item 4 --> */}
				<li class="nav-item">
					<a class="nav-link" data-bs-toggle="collapse" href="#collapseinstructors" role="button" aria-expanded="false" aria-controls="collapseinstructors">
						<i class="fas fa-user-tie fa-fw me-2"></i>الخبراء
					</a>
					{/* <!-- Submenu --> */}
					<ul class="nav collapse flex-column" id="collapseinstructors" data-bs-parent="#navbar-sidebar">
						<li data-bs-dismiss="offcanvas" class="nav-item"> <NavLink to='/dashboard/instructors' className="nav-link">قائمة الخبراء</NavLink></li>
						<li data-bs-dismiss="offcanvas" class="nav-item"> 
							<NavLink to='/dashboard/instructor-requests' className="nav-link">طلبات الانضمام
								{/* <span class="badge text-bg-success rounded-circle ms-2">2</span> */}
							</NavLink>
						</li>
						<li data-bs-dismiss="offcanvas" class="nav-item"> 
							<NavLink to='/dashboard/listing-requests' className="nav-link">طلبات عرض الصفحة
								{/* <span class="badge text-bg-success rounded-circle ms-2">2</span> */}
							</NavLink>
						</li>
					</ul>
				</li>
				
				{/* <!-- Menu item 5 --> */}
				<li data-bs-dismiss="offcanvas" class="nav-item"> <NavLink to='/dashboard/reviews' className="nav-link"><i class="far fa-comment-dots fa-fw me-2"></i>التقييمات</NavLink></li>

				{/* <!-- Menu item 6 --> */}
				<li data-bs-dismiss="offcanvas" class="nav-item"> <NavLink to='/dashboard/orders' className="nav-link"><i class="far fa-chart-bar fa-fw me-2"></i>المبيعات</NavLink></li>


				{/* <!-- Menu item 8 --> */}
				<li class="nav-item">
					<a class="nav-link" data-bs-toggle="collapse" href="#collapsechatgpt" role="button" aria-expanded="false" aria-controls="collapsechatgpt">
						<i class="bi bi-lock fa-fw me-2"></i>Chat GPT
					</a>
					{/* <!-- Submenu --> */}
					<ul class="nav collapse flex-column" id="collapsechatgpt" data-bs-parent="#navbar-sidebar">
						<li class="nav-item"> <NavLink to='/dashboard/chatgpt' className="nav-link">Genral</NavLink></li>
						<li class="nav-item"> <NavLink to='/dashboard/chatgpt-code' className="nav-link">Code</NavLink></li>
					</ul>
				</li>

				{/* <!-- Menu item 8 --> */}
				<li class="nav-item">
					<a class="nav-link" data-bs-toggle="collapse" href="#collapseauthentication" role="button" aria-expanded="false" aria-controls="collapseauthentication">
						<i class="bi bi-lock fa-fw me-2"></i>الحساب
					</a>
					{/* <!-- Submenu --> */}
					<ul class="nav collapse flex-column" id="collapseauthentication" data-bs-parent="#navbar-sidebar">
						<li class="nav-item"> <Link to='/forgot-password' class="nav-link">نسيت كلمة المرور</Link></li>
					</ul>
				</li>


			</ul>
			{/* <!-- Sidebar menu end --> */}

			{/* <!-- Sidebar footer START --> */}
			<div class="px-3 mt-auto pt-3">
				<div class="d-flex align-items-center justify-content-between text-primary-hover">
						<Link to='/' class="h5 mb-0 text-body" href="index.html" data-bs-toggle="tooltip" data-bs-placement="top" title="Home">
							<i class="bi bi-globe"></i>
						</Link>
						<span onClick={()=> dispatch(logout())} role='button' class="h5 mb-0 text-body" data-bs-toggle="tooltip" data-bs-placement="top" title="Sign out">
							<i class="bi bi-power"></i>
						</span>
				</div>
			</div>
			{/* <!-- Sidebar footer END --> */}
			
		</div>
	</div>
</nav>
  )
}

export default Sidebar