import { createSlice } from '@reduxjs/toolkit';


const initialState = {
	coursesFilter:{
        level:'',
        section:'',
        year:'',
        month:''
      },
	courses:[],
	sections:[],
	num_all_courses:0,
	num_published_courses:0,
	num_unpublished_courses:0,
	more_pages:false,
	page:1,
};


const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		setCoursesFilter: (state,action) => {
			state.coursesFilter = action.payload;
		},
		setCourses: (state,action) => {
			state.courses = action.payload.courses_list;
			state.sections = action.payload.sections_list;
			state.num_all_courses = action.payload.num_all_courses;
			state.num_published_courses = action.payload.num_published_courses;
			state.num_unpublished_courses = action.payload.num_unpublished_courses;
			state.more_pages = action.payload.more_pages;
			state.page = 1;
		},
		setMoreCourses: (state,action) => {
			console.log('the new set of coueses',action.payload.courses_list)
			state.courses.push(...action.payload.courses_list);
			state.more_pages = action.payload.more_pages;
		},
		setPage: (state,action) => {
			state.page = action.payload;
		},
	},
});


export const { 
	setCoursesFilter,
	setCourses,
	setMoreCourses,
	setPage,
 } = dashboardSlice.actions;
export default dashboardSlice.reducer;