import { publicApiSlice } from '../api/publicApiSlice'
import { apiSlice } from '../api/apiSlice'
import { addEnrolledCourse, resetCartItems, updateCartItems, updateEnrolledCourse } from './userSlice'



export const coursePublicApiSlice = publicApiSlice.injectEndpoints({
    endpoints: builder => ({
      getCourses: builder.query({
        query: () => `/courses/`,
        providesTags: (result, error, arg) => [{ type: 'Course', id: 'LIST' }]
      }),
      getCourseDetail: builder.query({
        query: (course_id) => `/courses/${course_id}`,
        providesTags: (result, error, arg) => [{ type: 'Course', id: arg }]
      }),
      getCoursePrice: builder.query({
        query: (course_id) => `/courses/course-price/${course_id}`,
      }),
    })
  })
  
export const courseApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
      getSections: builder.query({
        query: () => `/courses/sections/`,
        providesTags: (result, error, arg) => [{ type: 'Section', id: arg }]
      }),
      getUserCourses: builder.query({
        query: () => `/courses/user-courses/`,
        providesTags: (result, error, arg) => [{ type: 'UserCourses', id: 'List' }]
      }),
      getCoursePage: builder.query({
        query: (course_id) => `/courses/course-page/${course_id}/`,
        providesTags: (result, error, arg) => [{ type: 'CoursePage', id: arg }]
      }),
      getCourseDashboard: builder.query({
        query: (course_id) => `/courses/course-dashborad/${course_id}/`,
        providesTags: (result, error, arg) => [{ type: 'Course', id: arg }]
      }),
      createCourse: builder.mutation({
          query: courseObj => ({
              url: '/courses/',
              method: 'POST',
              body: courseObj
          }),
      }),
      createChapter: builder.mutation({
        query: (chapterObj) => ({
          url: '/courses/create-chapter/',
          method: 'POST',
          body: chapterObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course }]
      }),
      updateChapter: builder.mutation({
        query: ({chapterObj, chapter_id}) => ({
          url: `/courses/update-chapter/${chapter_id}/`,
          method: 'PATCH',
          body: chapterObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course }]
      }),
      deleteChapter: builder.mutation({
        query: ({chapter_id}) => ({
          url: `/courses/delete-chapter/${chapter_id}/`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course }]
      }),
      createLecture: builder.mutation({
        query: (lectureObj) => ({
          url: '/courses/upload-lecture/',
          method: 'POST',
          body: lectureObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course_id }]
      }),
      updateLecture: builder.mutation({
        query: ({lectureObj, lecture_id}) => ({
          url: `/courses/update-lecture/${lecture_id}/`,
          method: 'PATCH',
          body: lectureObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course_id }]
      }),
      deleteLecture: builder.mutation({
        query: ({lecture_id}) => ({
          url: `/courses/delete-lecture/${lecture_id}/`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course_id }]
      }),
      createAttachment: builder.mutation({
        query: (attachmentObj) => ({
          url: '/courses/upload-attachment/',
          method: 'POST',
          body: attachmentObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course_id }]
      }),
      updateAttachment: builder.mutation({
        query: ({attachmentObj, attachment_id}) => ({
          url: `/courses/update-attachment/${attachment_id}/`,
          method: 'PATCH',
          body: attachmentObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course_id }]
      }),
      deleteAttachment: builder.mutation({
        query: ({attachment_id}) => ({
          url: `/courses/delete-attachment/${attachment_id}/`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course_id }]
      }),
      updateCourseInfo: builder.mutation({
        query: ({infoObj, course_id}) => ({
          url: `/courses/course-dashborad/${course_id}/`,
          method: 'PATCH',
          body: infoObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course_id }]
      }),
      updateCourseDescription: builder.mutation({
        query: ({descriptionObj, course_id}) => ({
          url: `/courses/course-dashborad/${course_id}/`,
          method: 'PATCH',
          body: descriptionObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course_id }]
      }),
      updateCourseThumbnail: builder.mutation({
        query: ({thumbnailObj, course_id}) => ({
          url: `/courses/course-dashborad/${course_id}/`,
          method: 'PATCH',
          body: thumbnailObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course_id }]
      }),
      addCourseFaq: builder.mutation({
        query: ({faqObj, course_id}) => ({
          url: `/courses/course-dashborad/${course_id}/`,
          method: 'PATCH',
          body: faqObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course_id }]
      }),
      updateCourseFaq: builder.mutation({
        query: ({faqObj, course_id}) => ({
          url: `/courses/course-dashborad/${course_id}/`,
          method: 'PATCH',
          body: faqObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course_id }]
      }),
      deleteCourseFaq: builder.mutation({
        query: ({faqObj, course_id}) => ({
          url: `/courses/course-dashborad/${course_id}/`,
          method: 'PATCH',
          body: faqObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course_id }]
      }),
      updateCoursePublication: builder.mutation({
        query: ({publishObj, course_id}) => ({
          url: `/courses/course-dashborad/${course_id}/`,
          method: 'PATCH',
          body: publishObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.course_id }]
      }),
      enrollCourse: builder.mutation({
        query: (course_id) => ({
          url: `/courses/enroll/${course_id}/`,
          method: 'POST',
        }),
        async onQueryStarted( course_id, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled
            dispatch(addEnrolledCourse(course_id))
          } catch (err) {
            console.error('Failed to add item', err)
          }
        },
        invalidatesTags: (result, error, arg) => [{ type: 'UserCourses', id: 'List' }],
      }),
      addCartItem: builder.mutation({
        query: (course_id) => ({
          url: `/courses/cart-items/${course_id}/`,
          method: 'POST',
        }),
        async onQueryStarted( course_id, { dispatch, queryFulfilled }) {
          try {
            const { data: addedItem } = await queryFulfilled
            dispatch(updateCartItems({type:'add', item:addedItem}))
          } catch (err) {
            console.error('Failed to add item', err)
          }
      }
      }),
      removeCartItem: builder.mutation({
        query: (item) => ({
          url: `/courses/cart-items/${item.id}/`,
          method: 'DELETE',
        }),
        async onQueryStarted( item, { dispatch, queryFulfilled }) {
          dispatch(updateCartItems({type:'remove', item:item}))
          try {
              await queryFulfilled
          } catch {
            dispatch(updateCartItems({type:'add', item:item}))
          }
      }
      }),
      createOrder: builder.mutation({
        query: (paymentObj) => ({
          url: '/orders/',
          method: 'POST',
          body:paymentObj,
        }),
        async onQueryStarted( paymentObj, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            dispatch(resetCartItems())
            dispatch(updateEnrolledCourse(data))

          } catch (err) {
            console.error('Failed to create order', err)
          }
        },
        invalidatesTags: (result, error, arg) => [{ type: 'UserCourses', id: 'List' }],
      }),
      createSingleOrder: builder.mutation({
        query: ({paymentObj}) => ({
          url: '/orders/single-order/',
          method: 'POST',
          body:paymentObj,
        }),
        async onQueryStarted( {course_id}, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled
            dispatch(addEnrolledCourse(course_id))

          } catch (err) {
            console.error('Failed to create order', err)
          }
        },
        invalidatesTags: (result, error, arg) => [{ type: 'UserCourses', id: 'List' }],
      }),
      completedLecture: builder.mutation({
        query: ({lecture_id}) => ({
          url: `/courses/completed-lecture/${lecture_id}/`,
          method: 'POST',
        }),
        async onQueryStarted({ course_id, chapter_id, lecture_id }, { dispatch, queryFulfilled }) {
          // `updateQueryData` requires the endpoint name and cache key arguments,
          // so it knows which piece of cache state to update
          const patchResult = dispatch(
            courseApiSlice.util.updateQueryData('getCoursePage', course_id, draft => {
                  // The `draft` is Immer-wrapped and can be "mutated" like in createSlice
                  const course = draft
                  let chapIndex;
                  let lecIndex;
                  if (course) {
                    chapIndex = course.chapters.findIndex(ch => ch.id === chapter_id)
                    console.log(chapIndex)
                    
                    lecIndex = course.chapters[chapIndex].lectures.findIndex(lc => lc.id === lecture_id)
                    
                    
                    console.log('oh there is lecture', lecIndex)
                    course.chapters[chapIndex].lectures[lecIndex].is_completed = true
                    
                  }
              })
          )
          try {
              await queryFulfilled
              // const { data: ccoouurrssee } = await queryFulfilled
              // console.log('opimistic update2', ccoouurrssee)
          } catch {
              patchResult.undo()
          }
      }
      }),
      rateCourse: builder.mutation({
        query: ({reviewObj, course_id}) => ({
          url: `/courses/rate-course/${course_id}/`,
          method: 'POST',
          body: reviewObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'CoursePage', id: arg.course_id }]
      }),
      captureStopedPlace: builder.mutation({
        query: (captureObj) => ({
          url: `/courses/capture-stoped-place/`,
          method: 'PATCH',
          body: captureObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'CoursePage', id: arg.course_id }]
      }),
    })
  })

  export const { 
    useGetCoursePageQuery,
    useGetCourseDashboardQuery,
    useCreateCourseMutation, 
    useGetSectionsQuery,
    useGetUserCoursesQuery,
    useCreateChapterMutation, 
    useUpdateChapterMutation,
    useDeleteChapterMutation,
    useCreateLectureMutation,
    useUpdateLectureMutation,
    useDeleteLectureMutation,
    useCreateAttachmentMutation,
    useUpdateAttachmentMutation,
    useDeleteAttachmentMutation,
    useUpdateCourseInfoMutation,
    useUpdateCourseDescriptionMutation,
    useUpdateCourseThumbnailMutation,
    useAddCourseFaqMutation,
    useUpdateCourseFaqMutation,
    useDeleteCourseFaqMutation,
    useUpdateCoursePublicationMutation,
    useEnrollCourseMutation,
    useAddCartItemMutation,
    useRemoveCartItemMutation,
    useCreateOrderMutation,
    useCreateSingleOrderMutation,
    useCompletedLectureMutation,
    useRateCourseMutation,
    useCaptureStopedPlaceMutation,
  } = courseApiSlice;

  export const { useGetCoursesQuery, useGetCourseDetailQuery, useGetCoursePriceQuery } = coursePublicApiSlice;


  export const selectCourseDetail = (state, course_id) => coursePublicApiSlice.endpoints.getCourseDetail.select(course_id) (state)?.data ?? {};