import React, { useEffect, useState } from 'react'

function BackToTop() {
  const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
  })
  }
  return (
    <div class={`back-top ${showTopBtn ?'back-top-show' : ''}`} onClick={handleBackToTop}>
        <i class="bi bi-arrow-up-short position-absolute top-50 start-50 translate-middle"></i>
    </div>
  )
}

export default BackToTop