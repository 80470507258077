import React from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function WarningModal({show, title='Confirming', bodyText='Are you sure you want to proceed?', handleConfirm, handleClose, isLoading}) {


  
  return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header className='bg-dark'>
          <Modal.Title className='fs-5 text-white mb-0'>{title}</Modal.Title>
          <Button variant="light" className="mb-0" size="sm" onClick={handleClose} aria-label="Close"><i class="bi bi-x-lg"></i></Button>
        </Modal.Header>
        <Modal.Body>{bodyText}</Modal.Body>
        <Modal.Footer>
          <Button className='my-0' variant="outline-primary" onClick={handleClose}>
            إلغاء
          </Button>
          {isLoading ?
          <Button className='my-0' variant="danger-soft" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
           {' Loading...'}
          </Button> :
          <Button className='my-0' variant="danger-soft" onClick={handleConfirm}>
            تأكيد
          </Button>}
        </Modal.Footer>
    </Modal>
  )
}

export default WarningModal