import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { formattedDuration } from '../../utils/general';
import Rating from '../Rating/Rating';
import WrapedSpinner from '../Spinner/WrapedSpinner';

const levelArabic = {
  'All levels':"جميع المستويات",
  Beginner:"مبتدئ",
  Intermediate:"متوسط",
  Advanced:"متقدم",
  }

function SearchModal({showResults, setShowResults, results, isLoading, isError, query, setQuery,handleSearch}) {

  return (
     <Modal
        size="lg"
        show={showResults}
        onHide={() => setShowResults(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            نتائج البحث
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={handleSearch}>
            <Form.Group className="mb-3 input-group" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="search" 
                placeholder="ابحث..."
                autoFocus
                onChange={(e)=>setQuery(e.target.value)}
                value={query}
              />
              <button disabled={query ? false : true} class="btn btn-primary m-0" type="submit">بحث</button>
            </Form.Group>
          </Form>
          {isLoading ? <WrapedSpinner/> : !results?.courses?.length && !results?.instructors?.length ?
				<p class="fs-5 my-5 text-center" >نأسف لا توجد نتائج حسب المدخلات للبحث!</p> :

        <div class='my-4'>
          {results?.courses?.length ? 
          <>
          <h4>Courses:</h4>
          {results?.courses?.map((cr)=>(
            <div key={cr.id} class="card rounded overflow-hidden shadow my-2">

                <div class="card-body">
                  {/* <!-- Title --> */}
                  <div class="mb-2 mb-sm-3">
                    <h5 onClick={() => setShowResults(false)} class="card-title mb-0"><Link to={`/course-detail/${cr.id}`}>{cr.name}</Link></h5>
                  </div>
                  {/* <!-- Content --> */}
                  {/* <!-- Info --> */}
                  <ul class="list-inline mb-2">
                    <li class="list-inline-item text-dark mb-1 mb-sm-0"><i class="far fa-clock text-danger me-2"></i>{formattedDuration(cr.duration)}</li>
                    <li class="list-inline-item text-dark mb-1 mb-sm-0"><i class="fas fa-table text-orange me-2"></i>{cr.num_lectures} درس</li>
                    <li class="list-inline-item text-dark"><i class="fas fa-signal text-success me-2"></i>{levelArabic[cr.level]}</li>
                  </ul>
                  {/* <!-- Rating --> */}
                  {cr.rating ?
                    <ul class="list-inline mb-0">
                    <li class="list-inline-item me-0 small"><Rating value={cr.rating} color='#f7c32e'/></li>
                    <li class="list-inline-item ms-2 text-dark">{cr.rating}/5.0</li>
                  </ul> : null}
                </div>
          </div>
          ))} 
          </>
          : null}
          

          {results?.instructors?.length ? 
          <>
          <h4 class='mt-4'>الخبراء:</h4>
          {results?.instructors?.map((ins)=>(
            <div class="card shadow p-2 my-2">

          
              {/* <!-- Card body --> */}
                <div class="card-body">
                  {/* <!-- Title --> */}
                  <div class="d-sm-flex justify-content-sm-between mb-2 mb-sm-3">
                    <div>
                      <h5 onClick={() => setShowResults(false)} class="card-title mb-0"><Link to={`/instructors/${ins.id}`}>{ins.name}</Link></h5>
                      <p class="small mb-2 mb-sm-0">{ins.job_title}</p>
                    </div>
                    <span class="text-dark">{ins.rating}<i class="fas fa-star text-warning ms-1"></i></span>
                  </div>
                  {/* <!-- Content --> */}
                  <p class="text-truncate-2 mb-3">{ins.bio}</p>
                  {/* <!-- Info --> */}
                  <div class="d-sm-flex justify-content-sm-between align-items-center">
                    {/* <!-- Title --> */}
                    {!ins.specialty || ins.specialty ==='null' ? null : <h6 class="text-orange mb-0">{ins.specialty}</h6>}
                  </div>
                </div>
          </div>
          ))}
          </>
          : null}
          
        </div>
          }
        </Modal.Body>
      </Modal>
  )
}

export default SearchModal