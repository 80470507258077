import React from 'react';

const CustomToggle = React.forwardRef(({ children, onClick, classes }, ref) => (
    <div 
    role="button"
    className={classes}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    ref={ref}
    >
    {children}
    </div>
  ));

export default CustomToggle