import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from "uuid";




const toastSlice = createSlice({
	name: 'toast',
	initialState:[],
	reducers: {
		add_notification: (state, action) => {
			state.push({
                id: uuidv4(),
                ...action.payload 
            });
		},
		delete_notification: (state, action) => {
			return state.filter((notification) => notification.id !== action.payload);
		},
	}
});

export const { add_notification, delete_notification } = toastSlice.actions;
export default toastSlice.reducer;