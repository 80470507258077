import React, { Fragment, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import Header from '../../components/Header/Header'
import { useInstructorDashboardQuery } from '../../redux/features/instructorSlice'
import Banner from './Banner'
import Footer from './Footer'
import Sidebar from './Sidebar'
import Spinner from '../../components/Spinner/Spinner'
import GroupSpinner from '../../components/Spinner/GroupSpinner'
import ErrorFallback from '../../components/ErrorFallback/ErrorFallback'
import { ErrorBoundary } from 'react-error-boundary'

function InstructorDashboard() {

  const navigate = useNavigate()
  

  const { user } = useSelector(state => state.userState)
  const {
    data: instructor,
    isLoading,
    isSuccess,
    isError,
    error
  } = useInstructorDashboardQuery(user?.profile.instructor_id);
  
  return (
    <Fragment>
        <Header/>
        <div style={{'minHeight':'80vh'}}>
        {isLoading ? <Spinner/> :
        <>
        <Banner/>
          <section class="pt-0">
            <div class="container">
                <div class="row">
                    <ErrorBoundary
                      FallbackComponent={ErrorFallback}
                      onReset={() => navigate('/')}
                      resetKeys={[]}
                      >
                        <Sidebar/>
                      <Suspense fallback={<GroupSpinner />}>
                          <Outlet />
                      </Suspense>
                    </ErrorBoundary>
                </div>
            </div>
          </section>
        </>
        } 
        </div>
        <Footer/>
    </Fragment>
  )
}

export default InstructorDashboard