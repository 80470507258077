import React, { lazy } from 'react'
import { Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';
import Layout from "./Layout/Layout";
// import About from "./pages/About/About";
// import BecomeInstructor from "./pages/BecomeInstructor/BecomeInstructor";
// import Cart from "./pages/Cart/Cart";
// import ContactUs from "./pages/ContactUs/ContactUs";
// import CourseDetail from "./pages/CourseDetail/CourseDetail";
// import CoursePage from "./pages/CoursePage/CoursePage";
// import CoursesList from "./pages/Courses/CoursesList";
// import CreateCourse from "./pages/CreateCourse/CreateCourse";
// import FAQ from "./pages/FAQ/FAQ";
// import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
// import Home from "./pages/Home/Home";
// import Login from "./pages/Login.js/Login";
// import ResetPassword from "./pages/ResetPassword/ResetPassword";
// import Signup from "./pages/Signup/Signup";
// import SubmittedSuccessfuly from "./pages/SubmittedSuccessfuly/SubmittedSuccessfuly";
// import SuccessfulPage from "./pages/SuccessfulPage/SuccessfulPage";
// import Reviews from "./pages/Reviews/Reviews";
import InstructorDashboard from "./pages/InstructorDashboard/InstructorDashboard";
// import InstructorHome from "./pages/InstructorDashboard/InstructorHome";
// import InsContactInfo from "./pages/InstructorDashboard/InsContactInfo";
// import InsQualifications from "./pages/InstructorDashboard/InsQualifications";
// import InsrtructorStudents from "./pages/InstructorDashboard/InsrtructorStudents";
// import InstructorPayouts from "./pages/InstructorDashboard/InsructorPayouts";
// import InsSocialMedia from "./pages/InstructorDashboard/InsSocialMedia";
// import InstructorDeleteAccount from "./pages/InstructorDashboard/InstructorDeleteAccount";
// import InstructorEearnings from "./pages/InstructorDashboard/InstructorEearnings";
// import InstructorManageCourses from "./pages/InstructorDashboard/InstructorManageCourses";
// import InstructorOrders from "./pages/InstructorDashboard/InstructorOrders";
// import InstructorQuiz from "./pages/InstructorDashboard/InstructorQuiz";
// import InstructorReviews from "./pages/InstructorDashboard/InstructorReviews";
// import InstructorSettings from "./pages/InstructorDashboard/InstructorSettings";
// import InsUpdatePassword from "./pages/InstructorDashboard/InsUpdatePassword";
// import InsGeneralInfo from "./pages/InstructorDashboard/InsGeneralInfo";

// import InstructorProfile from "./pages/InstructorProfile/InstructorProfile";
// import InstructorsList from "./pages/Instructors/InstructorsList";

import OnlyRegisterd from "./Layout/OnlyRegisterd";
// import UserCourses from "./pages/UserCourses/UserCourses";
// import PaymentPage from "./pages/PaymentPage/PaymentPage";
// import EditUserProfile from "./pages/EditUserProfile/EditUserProfile";

// import CourseDashboard from "./pages/CourseDashboard/CourseDashboard";

import Dashboard from "./pages/Dashboard/Dashboard";
// import DashboardMain from "./pages/Dashboard/DashboardMain/DashboardMain";
// import DashboardCourses from "./pages/Dashboard/DashboardCourses/DashboardCourses";
// import PublishRequests from "./pages/Dashboard/PublishRequests/PublishRequests";
// import DashboardInstructors from "./pages/Dashboard/DashboardInstructors/DashboardInstructors";
// import InstructorRequests from "./pages/Dashboard/InstructorRequests/InstructorRequests";
// import DashbaordStudents from "./pages/Dashboard/DashbaordStudents/DashbaordStudents";
// import DashboardReviews from "./pages/Dashboard/DashboardReviews/DashboardReviews";
// import DashboardOrders from "./pages/Dashboard/DashboardOrders/DashboardOrders";
// import ListingRequests from "./pages/Dashboard/InstructorListing/ListingRequests";
// import InstructorListing from "./pages/InstructorDashboard/InstructorListing";
// import ChatGPT from "./pages/ChatGPT/ChatGPT";
// import ChatGPTCode from "./pages/ChatGPT/ChatGPTCode";

import PageNotFound from "./pages/PageNotFound/PageNotFound";



const Home = lazy(()=>import("./pages/Home/Home"));
const About = lazy(()=>import("./pages/About/About"));
const BecomeInstructor = lazy(()=>import("./pages/BecomeInstructor/BecomeInstructor"));
const InstructorProfile = lazy(()=>import("./pages/InstructorProfile/InstructorProfile"));
const InstructorsList = lazy(()=>import("./pages/Instructors/InstructorsList"));
const CoursesList = lazy(()=>import("./pages/Courses/CoursesList"));
const ContactUs = lazy(()=>import("./pages/ContactUs/ContactUs"));
const CourseDetail = lazy(()=>import("./pages/CourseDetail/CourseDetail"));
const FAQ = lazy(()=>import("./pages/FAQ/FAQ"));
const ForgotPassword = lazy(()=>import("./pages/ForgotPassword/ForgotPassword"));
const Login = lazy(()=>import("./pages/Login.js/Login"));
const ResetPassword = lazy(()=>import("./pages/ResetPassword/ResetPassword"));
const Signup = lazy(()=>import("./pages/Signup/Signup"));
const SubmittedSuccessfuly = lazy(()=>import("./pages/SubmittedSuccessfuly/SubmittedSuccessfuly"));
const SuccessfulPage = lazy(()=>import("./pages/SuccessfulPage/SuccessfulPage"));
const Reviews = lazy(()=>import("./pages/Reviews/Reviews"));

const Cart = lazy(()=>import("./pages/Cart/Cart"));
const CoursePage = lazy(()=>import("./pages/CoursePage/CoursePage"));
const CreateCourse = lazy(()=>import("./pages/CreateCourse/CreateCourse"));
const UserCourses = lazy(()=>import("./pages/UserCourses/UserCourses"));
const PaymentPage = lazy(()=>import("./pages/PaymentPage/PaymentPage"));
const EditUserProfile = lazy(()=>import("./pages/EditUserProfile/EditUserProfile"));

const CourseDashboard = lazy(()=>import("./pages/CourseDashboard/CourseDashboard"));

const InstructorHome = lazy(()=>import("./pages/InstructorDashboard/InstructorHome"));
const InsContactInfo = lazy(()=>import("./pages/InstructorDashboard/InsContactInfo"));
const InsQualifications = lazy(()=>import("./pages/InstructorDashboard/InsQualifications"));
const InsrtructorStudents = lazy(()=>import("./pages/InstructorDashboard/InsrtructorStudents"));
const InstructorPayouts = lazy(()=>import("./pages/InstructorDashboard/InsructorPayouts"));
const InsSocialMedia = lazy(()=>import("./pages/InstructorDashboard/InsSocialMedia"));
const InstructorDeleteAccount = lazy(()=>import("./pages/InstructorDashboard/InstructorDeleteAccount"));
const InstructorEearnings = lazy(()=>import("./pages/InstructorDashboard/InstructorEearnings"));
const InstructorManageCourses = lazy(()=>import("./pages/InstructorDashboard/InstructorManageCourses"));
const InstructorOrders = lazy(()=>import("./pages/InstructorDashboard/InstructorOrders"));
const InstructorQuiz = lazy(()=>import("./pages/InstructorDashboard/InstructorQuiz"));
const InstructorReviews = lazy(()=>import("./pages/InstructorDashboard/InstructorReviews"));
const InstructorSettings = lazy(()=>import("./pages/InstructorDashboard/InstructorSettings"));
const InsUpdatePassword = lazy(()=>import("./pages/InstructorDashboard/InsUpdatePassword"));
const InsGeneralInfo = lazy(()=>import("./pages/InstructorDashboard/InsGeneralInfo"));


const DashboardMain = lazy(()=>import("./pages/Dashboard/DashboardMain/DashboardMain"));
const DashboardCourses = lazy(()=>import("./pages/Dashboard/DashboardCourses/DashboardCourses"));
const PublishRequests = lazy(()=>import("./pages/Dashboard/PublishRequests/PublishRequests"));
const DashboardInstructors = lazy(()=>import("./pages/Dashboard/DashboardInstructors/DashboardInstructors"));
const InstructorRequests = lazy(()=>import("./pages/Dashboard/InstructorRequests/InstructorRequests"));
const DashbaordStudents = lazy(()=>import("./pages/Dashboard/DashbaordStudents/DashbaordStudents"));
const DashboardReviews = lazy(()=>import("./pages/Dashboard/DashboardReviews/DashboardReviews"));
const DashboardOrders = lazy(()=>import("./pages/Dashboard/DashboardOrders/DashboardOrders"));
const ListingRequests = lazy(()=>import("./pages/Dashboard/InstructorListing/ListingRequests"));
const InstructorListing = lazy(()=>import("./pages/InstructorDashboard/InstructorListing"));
const ChatGPT = lazy(()=>import("./pages/ChatGPT/ChatGPT"));
const ChatGPTCode = lazy(()=>import("./pages/ChatGPT/ChatGPTCode"));



const TRACKING_ID_1 = process.env.REACT_APP_TRACKING_ID_1;
const TRACKING_ID_2 = process.env.REACT_APP_TRACKING_ID_2;
ReactGA.initialize([
  {
    trackingId: TRACKING_ID_1,
  },
  {
    trackingId: TRACKING_ID_2,
  },
]);


function App() {

  // let location = useLocation();

  // React.useEffect(() => {
  //   // Google Analytics
  //   ga('send', 'pageview');
  // }, [location]);

  return (
    <Routes>


      <Route element={<Layout/>}>
        <Route path="/" element={<Home />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/signup" element={<Signup />}/>
        <Route path="/forgot-password" element={<ForgotPassword />}/>
        <Route path="/reset-password" element={<ResetPassword />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/reviews" element={<Reviews />}/>
        <Route path="/contact-us" element={<ContactUs />}/>
        <Route path="/Faq" element={<FAQ />}/>

        <Route path="/cart" element={<Cart />}/>
        

        <Route path="/successful-page/:type" element={<SuccessfulPage />} />
        <Route path="/submitted-successfuly" element={<SubmittedSuccessfuly />} />

        <Route path="/courses" element={<CoursesList />}/>
        <Route path="/course-detail/:course_id" element={<CourseDetail />}/>


        

        <Route path="/instructors" element={<InstructorsList />}/>
        <Route path="/instructors/:instructor_id" element={<InstructorProfile />}/>
        <Route path="/become-instructor" element={<BecomeInstructor />}/>
      </Route>

      <Route element={<OnlyRegisterd />}>
        <Route path="/payment-page/:course_id" element={<PaymentPage />} />
        <Route path="/edit-user-profile/:user_id" element={<EditUserProfile />} />
        <Route path="/user-courses" element={<UserCourses />}/>
        <Route path="/course-page/:course_id" element={<CoursePage />}/>
        <Route path="/create-course" element={<CreateCourse />}/>
        <Route path="/course-dashboard/:course_id" element={<CourseDashboard />}/>
      </Route>


      <Route path="/instructor-dashboard" element={<InstructorDashboard />}>
          {/* <Route index element={<InstructorHome/>}/> */}
          <Route index element={<InstructorManageCourses/>}/>
          <Route path="students" element={<InsrtructorStudents/>}/>
          <Route path="quizzes" element={<InstructorQuiz/>}/>
          <Route path="reviews" element={<InstructorReviews/>}/>
          <Route path="earnings" element={<InstructorEearnings/>}/>
          <Route path="orders" element={<InstructorOrders/>}/>
          <Route path="payouts" element={<InstructorPayouts/>}/>
          <Route path="general-info" element={<InsGeneralInfo/>}/>
          <Route path="qualifications" element={<InsQualifications/>}/>
          <Route path="contact-info" element={<InsContactInfo/>}/>
          <Route path="social-media" element={<InsSocialMedia/>}/>
          <Route path="update-password" element={<InsUpdatePassword/>}/>
          <Route path="settings" element={<InstructorSettings/>}/>
          <Route path="delete-account" element={<InstructorDeleteAccount/>}/>
          <Route path="listing-status" element={<InstructorListing/>}/>
      </Route>

      <Route path="/dashboard" element={<Dashboard/>}>
          <Route index element={<DashboardMain/>}/>
          <Route path="courses" element={<DashboardCourses/>}/>
          <Route path="publish-requests" element={<PublishRequests/>}/>
          <Route path="instructors" element={<DashboardInstructors/>}/>
          <Route path="instructor-requests" element={<InstructorRequests/>}/>
          <Route path="listing-requests" element={<ListingRequests/>}/>
          <Route path="students" element={<DashbaordStudents/>}/>
          <Route path="reviews" element={<DashboardReviews/>}/>
          <Route path="orders" element={<DashboardOrders/>}/>
          <Route path="chatgpt" element={<ChatGPT/>}/>
          <Route path="chatgpt-code" element={<ChatGPTCode/>}/>
      </Route>

      <Route path="*" element={<PageNotFound/>}/>
    </Routes>
  );
}

export default App;





