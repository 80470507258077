import React, { useState } from 'react';
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { useRemoveCartItemMutation } from '../../redux/features/courseSlice';
import Dropdown from 'react-bootstrap/Dropdown';
import WarningModal from '../modals/WarningModal';


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a class="nav-link mb-0 pb-0 stretched-link" href="#" ref={ref}
  onClick={(e) => {
    e.preventDefault();
    onClick(e);
  }}
>
  {children}
            
  </a>
));


const CustomMenu = React.forwardRef(
  ({ children, className, 'aria-labelledby': labeledBy }, ref) => {

    return (
      <div
        ref={ref}
        className={className}
        aria-labelledby={labeledBy}
      >
        
        {children}
      </div>
    );
  },
);

function Cart({cart_items}) {

  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const [showWarning, setShowWarning] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)


  const [removeCartItem ] = useRemoveCartItemMutation();

  const handleRemoveWarning = (item) => {
    setSelectedItem(item)
    setShowWarning(true)
    setShow(false)
  }
  const handleRemoveItem = async () => {
    setShowWarning(false)
    try {
      await removeCartItem(selectedItem).unwrap()

    } catch (err) {
        console.error('Failed to save the enroll', err)
    }
  }


  const handleCheckout = () => {
    navigate('/cart')
    setShow(false)
  }

  return (
    <>
    <WarningModal
      show={showWarning}
      title='تأكيد إزالة كورس'
      bodyText={`ستتم إزالة هذا الكورس (${selectedItem?.course.name})`}
      handleConfirm={handleRemoveItem}
      handleClose={()=>setShowWarning(false)}
      />
    
    <Dropdown
    show={show}
    onToggle={()=> setShow(prev => !prev)}
    className="nav-item position-relative overflow-visible">
        {/* <!-- Cart button --> */}
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <i class="bi bi-cart2 fs-4"></i>
        </Dropdown.Toggle>

        
        {/* <!-- badge --> */}
        <span class="position-absolute top-0 start-100 translate-middle badge rounded-circle text-bg-success mt-2 mt-xl-3 ms-n3 smaller">
          {cart_items?.length} 
            <span class="visually-hidden">unread messages</span>
        </span>

        {/* <!-- Cart dropdown menu START --> */}
        <Dropdown.Menu as={CustomMenu}
        className="dropdown-animation dropdown-menu-end dropdown-menu-size-md p-0 shadow-lg border-0">
          <div class="card bg-transparent">
                  <div class="card-header bg-transparent border-bottom py-4">
                      <h5 class="m-0">محتويات السلة</h5>
                  </div>
                  <div class="card-body p-0">

                      {/* <!-- Cart item START --> */}
                      {cart_items?.map((it, i)=>(
                        <div key={i} class="row p-3 g-2 align-items-center">
                          <div class="col-3">
                              <img class="rounded-2" src={it.course.thumbnail} alt="avatar"/>
                          </div>

                          <div class="col-9">
                              <div class="d-flex justify-content-between">
                                  <div>
                                    <h6 class="m-0">{it.course.name}</h6>
                                    {it.course.discount && it.course.discount_enabled ?
                                    <>
                                    <span class="text-decoration-line-through text-secondary me-2">${Math.floor(it.course.price)}</span>
                                    <span className='d-inline-block'>{Math.floor(it.course.price - it.course.discount)}<i class="fa-solid fa-dollar-sign text-success me-1"></i></span>
                                    </>
                                    : 
                                    <p class="mb-0">${Math.floor(it.course.price)}</p>}
                                  </div>
                                  <Button onClick={()=> handleRemoveWarning(it)} variant="danger-soft" size="xs"><i class="bi bi-x-lg"></i></Button>
                              </div>
                          </div>
                        </div>
                      ))}
                      {/* <!-- Cart item END --> */}
                      

                  </div>
                  {/* <!-- Button --> */}
                  <div class="card-footer bg-transparent border-top py-3 text-center d-flex justify-content-between position-relative">
                      <Button variant='light' size='sm' className="mb-0" onClick={()=> setShow(false)}>إغلاق</Button>
                      <Button variant='success' size='sm' className="mb-0" onClick={handleCheckout}>إتمام عملية الشراء</Button>
                      
                  </div>
          </div>
        </Dropdown.Menu>
        {/* <!-- Cart dropdown menu END --> */}
	</Dropdown>
  </>
  )
}

export default Cart