import React from 'react'

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="fallback-error">
        <p>تأسف حدث خطأ ما:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>العودة</button>
    </div>
  )
}

export default ErrorFallback