import { publicApiSlice } from '../api/publicApiSlice'
import { apiSlice } from '../api/apiSlice'
import { updateInstructorGenInfo } from './userSlice'

export const instructorPublicApiSlice = publicApiSlice.injectEndpoints({
  endpoints: builder => ({
    getInstructors: builder.query({
        query: () => '/instructors/',
    }),
    getInstructor: builder.query({
        query: (instructor_id) => `/instructors/${instructor_id}/`,
        providesTags: (result, error, arg) => [{ type: 'Instructor', id: arg }]
    }),
    registerInstructor: builder.mutation({
        query: instructoObj => ({
            url: '/instructors/',
            method: 'POST',
            body: instructoObj
        }),
    }),
  })
})

export const instructorApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    instructorDashboard: builder.query({
      query: (instructor_id) => `/instructors/dashboard/${instructor_id}/`,
      providesTags: (result, error, arg) => [{ type: 'Instructor', id: arg }]
    }),
    instructorOrders: builder.query({
      query: (page) => `/orders/instructor-orders/?page=${page}`,
      providesTags: (result, error, arg) => [{ type: 'Instructor', id:'list' }]
    }),
    becomeInstructor: builder.mutation({
        query: instructoObj => ({
            url: '/instructors/',
            method: 'POST',
            body: instructoObj
        }),
    }),
    updateInsGeneralInfo: builder.mutation({
      query: ({newInfo, instructor_id}) => ({
        url: `/instructors/dashboard/${instructor_id}/`,
        method: 'PATCH',
        body: newInfo
      }),
      async onQueryStarted( newInfo, { dispatch, queryFulfilled }) {
        try {
          const { data: instructor } = await queryFulfilled
          dispatch(updateInstructorGenInfo(instructor))
        } catch (err) {
          console.error('Failed to add item', err)
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Instructor', id: arg.id }]
    }),
    updateInsQualifications: builder.mutation({
      query: ({newInfo, instructor_id}) => ({
        url: `/instructors/dashboard/${instructor_id}/`,
        method: 'PATCH',
        body: newInfo
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Instructor', id: arg.id }]
    }),
    updateInsContacts: builder.mutation({
      query: ({newInfo, instructor_id}) => ({
        url: `/instructors/dashboard/${instructor_id}/`,
        method: 'PATCH',
        body: newInfo
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Instructor', id: arg.id }]
    }),
    updateInsSocials: builder.mutation({
      query: ({newInfo, instructor_id}) => ({
        url: `/instructors/dashboard/${instructor_id}/`,
        method: 'PATCH',
        body: newInfo
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Instructor', id: arg.id }]
    }),
    updateInsPassword: builder.mutation({
      query: ({newInfo, instructor_id}) => ({
        url: `/instructors/dashboard/${instructor_id}/`,
        method: 'PATCH',
        body: newInfo
      }),
    }),
    requestListing: builder.mutation({
      query: ({requestObj,instructor_id}) => ({
        url: `/instructors/listing-requests/`,
        method: 'POST',
        body: requestObj
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Instructor', id: arg.instructor_id }]
    }),
  })
})


export const selectInstructorResult = (state, instructor_id) => instructorPublicApiSlice.endpoints.getInstructor.select(instructor_id) (state)?.data ?? {};
export const selectInstructorDashbord = (state, instructor_id) => instructorApiSlice.endpoints.instructorDashboard.select(instructor_id) (state)?.data ?? {};





export const { useGetInstructorsQuery, useRegisterInstructorMutation, useGetInstructorQuery } = instructorPublicApiSlice
export const { 
  useBecomeInstructorMutation,
  useUpdateInsGeneralInfoMutation, 
  useInstructorDashboardQuery,
  useInstructorOrdersQuery,
  useUpdateInsQualificationsMutation,
  useUpdateInsContactsMutation,
  useUpdateInsSocialsMutation,
  useUpdateInsPasswordMutation,
  useRequestListingMutation,
  } = instructorApiSlice