import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import  logo_icon  from '../../assets/images/mine/logo-icon.png'
import  guidnance  from '../../assets/files/guidance.pdf'

const TWITTER_URL = process.env.REACT_APP_TWITTER_ACCOUNT;
const FACEBOOK_URL = process.env.REACT_APP_FACEBOOK_ACCOUNT;
const INSTAGRAM_URL = process.env.REACT_APP_INSTAGRAM_ACCOUNT;
const LINKEDIN_URL = process.env.REACT_APP_LINKEDIN_ACCOUNT;




function Footer({lightBackground}) {
  const navigate = useNavigate()
  const date = new Date
  const fullYear = date.getFullYear()


  


  return (
    <footer class={lightBackground ? "pt-5 bg-light" : "pt-5"}>
	<div class="container">
		{/* <!-- Row START --> */}
		<div class="row g-4">

			{/* <!-- Widget 1 START --> */}
			<div class="col-lg-3">
				{/* <!-- logo --> */}
				<div className='navbar-logo' onClick={()=>navigate('/')}>
					<img src={logo_icon} alt="logo"/>
					<span>ساريات</span>
				</div>
				<p class="my-3">نحن منصة باللغة العربية للتعليم والتدريب الالكتروني مختصة بالمحترفيين في المجالات المهنية من خلال جذب الخبراء لنقل معارفهم وخبراتهم لتحقيق التمكين المهني في العالم العربي</p>
				{/* <!-- Social media icon --> */}
				<ul class="list-inline mb-0 mt-3">
					<li class="list-inline-item"> <a class="btn btn-white btn-sm shadow px-2 text-facebook" target='_blank' href={FACEBOOK_URL}><i class="fab fa-fw fa-facebook-f"></i></a> </li>
					<li class="list-inline-item"> <a class="btn btn-white btn-sm shadow px-2 text-instagram" target='_blank' href={INSTAGRAM_URL}><i class="fab fa-fw fa-instagram"></i></a> </li>
					<li class="list-inline-item"> <a class="btn btn-white btn-sm shadow px-2 text-twitter" target='_blank' href={TWITTER_URL}><i class="fab fa-fw fa-twitter"></i></a> </li>
					<li class="list-inline-item"> <a class="btn btn-white btn-sm shadow px-2 text-linkedin" target='_blank' href={LINKEDIN_URL}><i class="fab fa-fw fa-linkedin-in"></i></a> </li>
				</ul>
			</div>
			{/* <!-- Widget 1 END --> */}

			{/* <!-- Widget 2 START --> */}
			<div class="col-lg-6">
				<div class="row g-4">
					{/* <!-- Link block --> */}
					<div class="col-6 col-md-4">
						<h5 class="mb-2 mb-md-4">المنصة</h5>
						<ul class="nav flex-column">
							<li class="nav-item"><Link to='/about' class="nav-link">عن المنصة</Link></li>
							<li class="nav-item"><Link to='/contact-us' class="nav-link">تواصل معنا</Link></li>
							{/* <li class="nav-item"><a class="nav-link" href="#">News and Blogs</a></li>
							<li class="nav-item"><a class="nav-link" href="#">Library</a></li>
							<li class="nav-item"><a class="nav-link" href="#">Career</a></li> */}
						</ul>
					</div>
									
					{/* <!-- Link block --> */}
					<div class="col-6 col-md-4">
						<h5 class="mb-2 mb-md-4">معلومات</h5>
						<ul class="nav flex-column">
							<li class="nav-item"><Link to='/faq' class="nav-link">الأسئلة الشائعة</Link></li>
							<li class="nav-item"><Link to='/reviews' class="nav-link">التقييمات</Link></li>
							{/* <li class="nav-item"><a class="nav-link" href="#">Forum</a></li>
							<li class="nav-item"><a class="nav-link" href="#">Sitemap</a></li> */}
						</ul>
					</div>

					{/* <!-- Link block --> */}
					<div class="col-6 col-md-4">
						<h5 class="mb-2 mb-md-4">التدريب</h5>
						<ul class="nav flex-column">
							<li class="nav-item"><Link to="/become-instructor" class="nav-link">انضم كخبير</Link></li>
							<li class="nav-item"><a href={guidnance} download class="nav-link">تحميل الدليل الإرشادي</a></li>
							{/* <li class="nav-item"><span onClick={()=>downloadPDF('files/Amal_poems.pdf',endpoint)} class="nav-link">الدليل الإرشادي</span></li> */}
							{/* <li class="nav-item"><a class="nav-link" href="#">Terms &amp; Conditions</a></li> */}
						</ul>
					</div>
				</div>
			</div>
			{/* <!-- Widget 2 END --> */}

			{/* <!-- Widget 3 START --> */}
			<div class="col-lg-3">
				<h5 class="mb-2 mb-md-4">للتواصل</h5>
				{/* <!-- Time --> */}
				<p class="mb-2">
					اتصل بنا:<span class="h6 fw-light ms-2 d-inline-block dr-ltr">+1 (650) 4570240</span>
					{/* <span class="d-block small">(9:AM to 8:PM GMT)</span> */}
				</p>

				<p class="mb-0">الإيميل:<span class="h6 fw-light ms-2">info@sareyat.com</span></p>

				
                 {/* <!-- Row END --> */}
			</div> 
			{/* <!-- Widget 3 END --> */}
		</div>
        {/* <!-- Row END --> */}

		{/* <!-- Divider --> */}
		<hr class="mt-4 mb-0"/>

		{/* <!-- Bottom footer --> */}
		<div class="py-3">
			<div class="container px-0">
				<div class="d-lg-flex justify-content-between align-items-center py-3 text-center text-md-left">
					{/* <!-- copyright text --> */}
					<div class="text-primary-hover">جميع الحقوق محفوظة ©{`${fullYear}`}  </div>
				</div>
			</div>
		</div>
	</div>
</footer>
  )
}

export default Footer