import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { Fragment, Suspense } from "react";
import Toast from "../components/Toast/Toast";
import Header from "../components/Header/Header";
import BackToTop from "../components/BackToTop/BackToTop";
import ShortFooter from "../components/Footer/ShortFooter";
import GroupSpinner from "../components/Spinner/GroupSpinner";
import ErrorFallback from "../components/ErrorFallback/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";

const OnlyRegisterd = () => {
    const navigate = useNavigate()

    const { isAuthenticated } = useSelector(state => state.userState);
    const location = useLocation()

    return (
        isAuthenticated
            ?
            <Fragment>
                <Toast autoDeleteTimeout={4000} />
                <Header/>
                <main className='layout-main'>
                <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
                resetKeys={[]}
                >
                    <Suspense fallback={<GroupSpinner />}>
                        <Outlet />
                    </Suspense>
                </ErrorBoundary>
                </main>
                <ShortFooter/>
                <BackToTop/>
            </Fragment> 
            : <Navigate to="/login" state={{ from: location }} replace />
    )
}
export default OnlyRegisterd