import React from 'react';
import  logo_icon  from '../../assets/images/mine/logo-icon.png'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Profile from './Profile';
import { useDispatch, useSelector } from 'react-redux';
import Cart from './Cart';
import Search from './Search';

function Header() {
  const { isAuthenticated, user } = useSelector(state => state.userState)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const handleSignin = () => {
    navigate('/login', { replace: true, state:{ from: location } })
  }
  return (
    <header class="navbar-light">
          <nav class="navbar navbar-expand-xl">
            <div class="container d-flex align-items-center">
            {/* Logo START */}
            <div onClick={()=>navigate('/')} className='navbar-logo'>
              <img src={logo_icon} alt="logo"/>
              <span>ساريات</span>
            </div>
            {/* Logo END */}

            {/* Responsive navbar toggler */}

            <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-animation">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>

             {/* Main navbar START  */}
              <div class="navbar-collapse collapse flex-grow-0" id="navbarCollapse">

                {/* Nav Main menu START */}
                <ul class="navbar-nav navbar-nav-scroll font-kufi">
                  
                  
                {/* <!-- Nav item 1 Home --> */}
                  <NavLink to='/' end>
                    {({ isActive, isPending }) => (
                        <li data-bs-toggle="collapse" data-bs-target="#navbarCollapse" class="nav-item">
                          <span role='button' className={isActive ? "nav-link active" : "nav-link"}>الرئيسية</span>
                        </li>
                      )}
                  </NavLink>

                {/* <!-- Nav item 2 Courses --> */}
                  <NavLink to='/courses'>
                    {({ isActive, isPending }) => (
                        <li data-bs-toggle="collapse" data-bs-target="#navbarCollapse" class="nav-item">
                          <span role='button' className={isActive ? "nav-link active" : "nav-link"}>الكورسات</span>
                        </li>
                      )}
                  </NavLink>

                  {/* <!-- Nav item 3 Instructors --> */}
                  <NavLink to='/instructors'>
                    {({ isActive, isPending }) => (
                        <li data-bs-toggle="collapse" data-bs-target="#navbarCollapse" class="nav-item">
                          <span role='button' className={isActive ? "nav-link active" : "nav-link"}>الخبراء</span>
                        </li>
                      )}
                  </NavLink>




                </ul>
                {/* Nav Main menu END */}

              </div>

            {/* Main navbar END */}
            {/* <!-- Header right side START --> */}
				<div class="d-flex justify-content-end pe-0">
					<ul class="nav flex-row align-items-center list-unstyled ms-xl-auto">
						{/* <!-- Add to cart --> */}
            {user?.cart_items.length ? <Cart cart_items={user?.cart_items}/> : null}
		
						{/* <!-- Search --> */}
            <Search/>

						{/* <!-- Sign In button --> */}
						<li class="nav-item ms-2">
            {isAuthenticated ?
              <Profile/> :
              <button onClick={handleSignin} class="btn btn-sm btn-dark mb-0">تسجيل الدخول</button>
            }
						</li>
					</ul>
				</div>
				{/* <!-- Header right side END --> */}

          

            </div>
          </nav>
        </header>
  )
}

export default Header