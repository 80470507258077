import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import SearchModal from '../SearchModal/SearchModal';
import CustomMenu from './CustomMenu';
import CustomToggle from './CustomToggle';

const baseURL = process.env.REACT_APP_BASE_URL

function Search() {

  const [show, setShow] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);


  

  const handleSearch = async (e) => {
    e.preventDefault()
    setShowResults(true)
    setShow(false)
    setIsLoading(true)
    try {
			const res = await fetch(`${baseURL}/general/search/?query=${query}`);

      if (!res.ok) {
        throw new Error('Request failed!');
			}

			const data = await res.json();
      setResults(data)
      setIsLoading(false)
      console.log(data)

			
		} catch (err) {
			setIsError(true);
      setIsLoading(false)
		}
  }



  return (
    <>
      <SearchModal
        showResults={showResults} 
        setShowResults={setShowResults}
        results={results}
        isLoading={isLoading}
        isError={isError}
        setQuery={setQuery}
        query={query}
        handleSearch={handleSearch}
      />
      <Dropdown 
        className="nav-item dropdown nav-search d-none d-md-block"
        show={show}
        onToggle={()=> setShow(prev => !prev)}
      >
        <Dropdown.Toggle as={CustomToggle} classes="nav-link mb-0">
        <a  role="button">
                <i class="bi bi-search fs-4"> </i>
            </a>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu}
            className="dropdown-menu dropdown-menu-end shadow rounded p-2">
            
            <form class="input-group" onSubmit={handleSearch}>
                    <input onChange={(e)=>setQuery(e.target.value)} value={query} class="form-control border-primary" type="search" placeholder="ابحث..." aria-label="Search"/>
                    <button disabled={query ? false : true} class="btn btn-primary m-0" type="submit">ابحث</button>
                </form>



        </Dropdown.Menu>
      
    </Dropdown>
  </>

  )
}

export default Search