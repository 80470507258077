import { createSlice } from '@reduxjs/toolkit';
import { actionLogin, actionRegister, login, register } from '../actions/auth';
import dayjs from 'dayjs';
import jwt_decode from "jwt-decode";


let isAuthenticated = false;
let userObj = null;

if (localStorage.getItem('userInfo')) {
	let userInfo = JSON.parse(localStorage.getItem('userInfo'))
	let decodedRefresh = userInfo.token?.refresh ? jwt_decode(userInfo.token.refresh) : null
	if (decodedRefresh && dayjs.unix(decodedRefresh.exp) > 1) {
		userObj = userInfo
		isAuthenticated = true
	}
}

const initialState = {
	isAuthenticated,
	user: userObj,
	loading: false,
	error: null,
	isSaved: true,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		logout: state => {
			state.isAuthenticated = false;
			state.user = null
			localStorage.removeItem('userInfo')
		},
		tokenReceived: (state,action) => {
			let refreshedUser = {...state.user, token:action.payload}
            localStorage.setItem('userInfo', JSON.stringify(refreshedUser))
			return {...state, user:refreshedUser}
		},
		saveMe: (state, action) => {
			state.isSaved = action.payload;
		},

		instructorRegistered: (state, action) => {
			state.isAuthenticated = true;
			state.user = action.payload.updated_user
			localStorage.setItem('userInfo', JSON.stringify(action.payload.updated_user))
		},
		addEnrolledCourse: (state, action) => {
			state.user.enrolled_courses.push(action.payload)
			localStorage.setItem('userInfo', JSON.stringify(state.user))
		},
		updateCartItems: (state, action) => {
			const { type, item } = action.payload

			if (type==='add') {
				state.user.cart_items.push(item)
				state.user.cart_courses_ids.push(item.course.id)
				localStorage.setItem('userInfo', JSON.stringify(state.user))
			} else if (type==='remove') {
				let newCartItems = state.user.cart_items.filter(it => it.course.id !== item.course.id)
				let newCartCourse = state.user.cart_courses_ids.filter(id => id !== item.course.id)
				state.user.cart_items = newCartItems
				state.user.cart_courses_ids = newCartCourse
				localStorage.setItem('userInfo', JSON.stringify(state.user))
			}

			
		},
		resetCartItems: (state, action) => {
			state.user.cart_items = []
			state.user.cart_courses_ids = []
			localStorage.setItem('userInfo', JSON.stringify(state.user))
		},
		updateEnrolledCourse: (state, action) => {
			console.log(action.payload)
			const { items } = action.payload
			items.forEach(element => {
				state.user.enrolled_courses.push(element.course.id)
			});
			localStorage.setItem('userInfo', JSON.stringify(state.user))
		},
		updateUserProfile: (state, action) => {
			let profile = action.payload
			state.user.name = `${profile.first_name} ${profile.last_name}`
			state.user.profile = profile
			localStorage.setItem('userInfo', JSON.stringify(state.user))
		},
		updateInstructorGenInfo: (state, action) => {
			let instructor = action.payload
			state.user.name = `${instructor.first_name} ${instructor.last_name}`
			state.user.profile.first_name = instructor.first_name
			state.user.profile.last_name = instructor.last_name
			state.user.profile.avatar = instructor.avatar
			localStorage.setItem('userInfo', JSON.stringify(state.user))
		},
	},
	extraReducers: builder => {
		builder
			.addCase(register.pending, state => {
				state.loading = true;
			})
			.addCase(register.fulfilled, (state, action) => {
				state.loading = false;
				state.isAuthenticated = true;
				state.user = action.payload
				state.error = null
				if (state.isSaved) {
					localStorage.setItem('userInfo', JSON.stringify(action.payload))
				}
			})
			.addCase(register.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload
			})
			.addCase(actionRegister.pending, state => {
				state.loading = true;
			})
			.addCase(actionRegister.fulfilled, (state, action) => {
				state.loading = false;
				state.isAuthenticated = true;
				state.user = action.payload
				localStorage.setItem('userInfo', JSON.stringify(action.payload))
				// if (state.isSaved) {
				// 	localStorage.setItem('userInfo', JSON.stringify(action.payload))
				// }
			})
			.addCase(actionRegister.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload
			})
			.addCase(login.pending, state => {
				state.loading = true;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.loading = false;
				state.isAuthenticated = true;
				state.user = action.payload
				state.error = null
				if (state.isSaved) {
					localStorage.setItem('userInfo', JSON.stringify(action.payload))
				}
			})
			.addCase(login.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload
			})
			.addCase(actionLogin.pending, state => {
				state.loading = true;
			})
			.addCase(actionLogin.fulfilled, (state, action) => {
				state.loading = false;
				state.isAuthenticated = true;
				state.user = action.payload
				localStorage.setItem('userInfo', JSON.stringify(action.payload))
				// if (state.isSaved) {
				// 	localStorage.setItem('userInfo', JSON.stringify(action.payload))
				// }
			})
			.addCase(actionLogin.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload
			});
	},
});

export const { 
	logout,
	tokenReceived, 
	instructorRegistered, 
	saveMe, 
	addEnrolledCourse, 
	updateCartItems, 
	resetCartItems, 
	updateEnrolledCourse,
	updateUserProfile,
	updateInstructorGenInfo,
 } = userSlice.actions;
export default userSlice.reducer;
