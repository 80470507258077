import React, { Suspense } from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import GroupSpinner from '../../components/Spinner/GroupSpinner';
import ErrorFallback from '../../components/ErrorFallback/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';

function Dashboard() {
  const navigate = useNavigate()

  const { isAuthenticated } = useSelector(state => state.userState);
  return (
    isAuthenticated
    ?
    <div>
      <Sidebar/>
      <div class="page-content">
        <Topbar/>
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => navigate('/')}
            resetKeys={[]}
            >
            <Suspense fallback={<GroupSpinner />}>
                <Outlet />
            </Suspense>
        </ErrorBoundary>
      </div>
    </div>
    : 
    <Navigate to="/" replace />
  )
}

export default Dashboard