import React from 'react'
import { Link, NavLink } from 'react-router-dom'

function Sidebar() {
  return (
    <div class="col-xl-3">
				{/* <!-- Responsive offcanvas body START --> */}
				<div class="offcanvas-xl offcanvas-end" tabIndex="-1" id="offcanvasSidebar" aria-labelledby="offcanvasSidebarLabel">
					{/* <!-- Offcanvas header --> */}
					<div class="offcanvas-header bg-light">
						<h5 class="offcanvas-title" id="offcanvasNavbarLabel">صفحتي</h5>
						<button  type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close"></button>
					</div>
					{/* <!-- Offcanvas body --> */}
					<div class="offcanvas-body p-3 p-xl-0">
						<div class="bg-dark border rounded-3 pb-0 p-3 w-100">
							{/* <!-- Dashboard menu --> */}
							<div class="list-group list-group-dark list-group-borderless">
								<NavLink class="nav-link" to='/instructor-dashboard' end>
                  {({ isActive, isPending }) => (
                      <li className={isActive ? "list-group-item active" : "list-group-item"} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar">
                        <i class="bi bi-ui-checks-grid fa-fw me-2"></i>كورساتي
                      </li>
                    )}
								</NavLink>
								{/* <NavLink class="nav-link" to='/instructor-dashboard/courses'>
                  {({ isActive, isPending }) => (
                      <li className={isActive ? "list-group-item active" : "list-group-item"} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar">
                        <i class="bi bi-basket fa-fw me-2"></i>كورساتي
                      </li>
                    )}
								</NavLink> */}
								<NavLink class="nav-link" to='/instructor-dashboard/orders'>
                  {({ isActive, isPending }) => (
                      <li className={isActive ? "list-group-item active" : "list-group-item"} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar">
                        <i class="bi bi-folder-check fa-fw me-2"></i>المبيعات
                      </li>
                    )}
								</NavLink>
								<NavLink class="nav-link" to='/instructor-dashboard/general-info'>
                  {({ isActive, isPending }) => (
                      <li className={isActive ? "list-group-item active" : "list-group-item"} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar">
                        <i class="bi bi-pencil-square fa-fw me-2"></i>البيانات العامة
                      </li>
                    )}
								</NavLink>
								<NavLink class="nav-link" to='/instructor-dashboard/qualifications'>
                  {({ isActive, isPending }) => (
                      <li className={isActive ? "list-group-item active" : "list-group-item"} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar">
                        <i class="bi bi-vector-pen fa-fw me-2"></i>الكفاءة
                      </li>
                    )}
								</NavLink>
								<NavLink class="nav-link" to='/instructor-dashboard/contact-info'>
                  {({ isActive, isPending }) => (
                      <li className={isActive ? "list-group-item active" : "list-group-item"} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar">
                        <i class="bi bi-person-rolodex fa-fw me-2"></i>وسائل الاتصال
                      </li>
                    )}
								</NavLink>
								<NavLink class="nav-link" to='/instructor-dashboard/social-media'>
                  {({ isActive, isPending }) => (
                      <li className={isActive ? "list-group-item active" : "list-group-item"} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar">
                        <i class="bi bi-twitter fa-fw me-2"></i>وسائل التواصل الاجتماعي
                      </li>
                    )}
								</NavLink>
								<NavLink class="nav-link" to='/instructor-dashboard/update-password'>
                  {({ isActive, isPending }) => (
                      <li className={isActive ? "list-group-item active" : "list-group-item"} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar">
                        <i class="bi bi-key fa-fw me-2"></i>تحدبث كلمة المرور
                      </li>
                    )}
								</NavLink>
								<NavLink class="nav-link" to="/instructor-dashboard/listing-status">
                  {({ isActive, isPending }) => (
                      <li className={isActive ? "list-group-item active" : "list-group-item"} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar">
                        <i class="fa-solid fa-list-ul me-2"></i>قائمة الخبراء
                      </li>
                    )}
								</NavLink>

                {/* <NavLink class="nav-link" to='/instructor-dashboard/students'>
                  {({ isActive, isPending }) => (
                      <li className={isActive ? "list-group-item active" : "list-group-item"} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar">
                        <i class="bi bi-people fa-fw me-2"></i>Students
                      </li>
                    )}
								</NavLink> */}
                {/* <NavLink class="nav-link" to="/instructor-dashboard/delete-account">
                  {({ isActive, isPending }) => (
                      <li className={isActive ? "list-group-item active" : "list-group-item"} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar">
                        <i class="bi bi-trash fa-fw me-2"></i>Delete Account
                      </li>
                    )}
								</NavLink> */}
								{/* <li class="list-group-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar"><Link to='/instructor-dashboard/students' class="nav-link"><i class="bi bi-people fa-fw me-2"></i>Students</Link></li> */}
								{/* <Link to='/instructor-dashboard/quizzes' class="nav-link"><i class="bi bi-question-diamond fa-fw me-2"></i>Quiz</Link> */}
								{/* <Link to='/instructor-dashboard/reviews' class="nav-link"><i class="bi bi-star fa-fw me-2"></i>Reviews</Link> */}
								{/* <Link to='/instructor-dashboard/earnings' class="nav-link"><i class="bi bi-graph-up fa-fw me-2"></i>Earnings</Link> */}
								{/* <Link to='/instructor-dashboard/orders' class="nav-link"><i class="bi bi-folder-check fa-fw me-2"></i>Orders</Link> */}
								{/* <Link to='/instructor-dashboard/payouts' class="nav-link"><i class="bi bi-wallet2 fa-fw me-2"></i>Payouts</Link> */}
								{/* <li class="list-group-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar"><Link to='/instructor-dashboard/general-info' class="nav-link"><i class="bi bi-pencil-square fa-fw me-2"></i>Edit General Info</Link></li> */}
								{/* <li class="list-group-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar"><Link to='/instructor-dashboard/qualifications' class="nav-link"><i class="bi bi-vector-pen fa-fw me-2"></i>Edit Qualifications</Link></li> */}
								{/* <li class="list-group-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar"><Link to='/instructor-dashboard/contact-info' class="nav-link"><i class="bi bi-person-rolodex fa-fw me-2"></i>Edit Contact Info</Link></li> */}
								{/* <li class="list-group-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar"><Link to='/instructor-dashboard/social-media' class="nav-link"><i class="bi bi-twitter fa-fw me-2"></i>Social Media Links</Link></li> */}
								{/* <li class="list-group-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar"><Link to='/instructor-dashboard/update-password' class="nav-link"><i class="bi bi-key fa-fw me-2"></i>Update Password</Link></li> */}
								{/* <Link to='/instructor-dashboard/settings' class="nav-link" href="instructor-setting.html"><i class="bi bi-gear fa-fw me-2"></i>Settings</Link> */}
								{/* <li class="list-group-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar"><Link to="/instructor-dashboard/delete-account" class="nav-link"><i class="bi bi-trash fa-fw me-2"></i>Delete Account</Link></li> */}
								<Link to='/' class="list-group-item text-danger bg-danger-soft-hover"><i class="fas fa-sign-out-alt fa-fw me-2"></i>تسجيل الخروج</Link>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Responsive offcanvas body END --> */}
			</div>
  )
}

export default Sidebar