import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import  maleAvatar  from '../../assets/images/mine/male_avatar.svg'
import { logout } from '../../redux/features/userSlice';
import CustomMenu from './CustomMenu';
import CustomToggle from './CustomToggle';






function Profile() {

  const navigate = useNavigate()

  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.userState)

  const handleNavigate = (path) => {
    setShow(false)
    navigate(path)
  }


  return (
    <Dropdown 
      className="ms-1 ms-lg-0"
      show={show}
      onToggle={()=> setShow(prev => !prev)}
    >
    <Dropdown.Toggle as={CustomToggle} classes="avatar avatar-sm p-0">
      <img class="avatar-img rounded-circle" src={user.profile.avatar ? user.profile.avatar : maleAvatar} alt="avatar"/>
    </Dropdown.Toggle>

    <Dropdown.Menu as={CustomMenu}
        className="dropdown-animation dropdown-menu-end shadow pt-3">
         
              {/* <!-- Profile info --> */}
              <li class="px-3 mb-3">
                <div class="d-flex align-items-center">
                  {/* <!-- Avatar --> */}
                  <div class="avatar me-3">
                    <img class="avatar-img rounded-circle shadow" src={user.profile.avatar ? user.profile.avatar : maleAvatar}  alt="avatar"/>
                  </div>
                  <div>
                    <Link to={`/edit-user-profile/${user.id}`} class="h6">{user.name}</Link>
                    <p class="small m-0">{user.email}</p>
                  </div>
                </div>
              </li>
              <li> <hr class="dropdown-divider"/></li>
              {/* <!-- Links --> */}
              { user.is_admin ?
              <li><button onClick={()=>handleNavigate('/dashboard')} class="dropdown-item"><i class="bi bi-ui-checks-grid fa-fw me-2"></i>لوحة التحكم</button></li>
              : user.profile.is_instructor ?
              <li><button onClick={()=>handleNavigate('/instructor-dashboard')} class="dropdown-item"><i class="bi bi-ui-checks-grid fa-fw me-2"></i>لوحة التحكم</button></li>
              :
              <li><button onClick={()=>handleNavigate(`/edit-user-profile/${user.id}`)} class="dropdown-item"><i class="bi bi-person fa-fw me-2"></i>الملف الشخصي</button></li>}

              <li><button onClick={()=>handleNavigate('/user-courses')} class="dropdown-item"><i class="bi bi-basket fa-fw me-2"></i>كورساتي</button></li>
              <li onClick={()=> dispatch(logout())}>
                <button class="dropdown-item bg-danger-soft-hover"><i class="bi bi-power fa-fw me-2"></i>تسجيل الخروج</button>
              </li>

    </Dropdown.Menu>
    
  </Dropdown>
  )
}

export default Profile