import React, { Fragment, Suspense } from 'react'
import { Outlet, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Toast from '../components/Toast/Toast';
import BackToTop from '../components/BackToTop/BackToTop';
import GroupSpinner from '../components/Spinner/GroupSpinner';
import ErrorFallback from '../components/ErrorFallback/ErrorFallback';

function Layout() {
    const navigate = useNavigate()

    return (
        <Fragment>
            <Toast autoDeleteTimeout={4000} />
            <Header/>
            <main className='layout-main'>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/courses')}
                resetKeys={[]}
                >
                <Suspense fallback={<GroupSpinner />}>
                    <Outlet />
                </Suspense>
            </ErrorBoundary>
            </main>
            <Footer lightBackground/>
            {/* <!-- Back to top --> */}
            <BackToTop/>
        </Fragment>
    )
}

export default Layout;